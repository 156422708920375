@import "./variable.scss";
/* 顶部 */

.App {
  height: 100vh;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.App > .header-nav {
  position: relative;
  z-index: 1;

  a {
    color: black;
    font-family: "NanumSquareB";
  }

  .spec-a {
    position: relative;
    height: 100px;
    display: inline-block;
    padding: 0 6px;
  }

  .tab {
    height: 40px;
    padding: 8px 12px;

    dd li {
      cursor: pointer;
    }

    dd li:hover,
    dd li.active {
      a {
        color: $active_color;
      }
    }

    .drop-spec-outer {
      position: relative;
      transform: translateY(-10px);
      display: none;
    }

    .spec-a:hover .drop-spec-outer {
      display: block;
    }

    .triangle-up {
      width: 22px;
      height: 22px;
      overflow: hidden;
      position: absolute;
      top: 10px;
      left: 50%;
      margin-left: -11px;
      background-color: white;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -16px;
        left: 50%;
        margin-left: -12px;
        width: 20px;
        height: 20px;
        border: 2px solid $point;
        border-radius: 2px;
        transform: rotateZ(45deg);
        transform-origin: center center;
      }
    }

    .drop-spec {
      display: flex;
      width: 291px;
      height: 54px;
      border-radius: 37px;
      border: solid 2px $active_color;
      background-color: white;
      position: absolute;
      left: 50%;
      top: 30px;
      transform: translateX(-50%);
      padding: 18px 30px;

      justify-content: space-evenly;

      a {
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          color: $active_color;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }

  dl dt {
    float: left;

    span {
      height: 21px;
      font-size: 18px;
      font-weight: bold;
      color: #111111;
      margin-left: 10px;
      position: relative;
      top: -5.5px;
    }
  }

  dl dd {
    float: right;

    span {
      height: 17px;
      font-size: 14px;
      color: #2267ff;
    }
  }

  dl ul {
    display: flex;
  }

  dl li {
    margin-left: 55px;
  }
}

.contact-popup {
  width: 258px;
  height: 289px;
  border-radius: 4px;
  background-color: #ffffff;
  position: fixed;
  top: calc(50% - 149px);
  z-index: 10;
  align-items: center;
  right: calc(50% - 129px);
  left: calc(50% - 129px);

  .contact-message {
    width: 190px;
    height: 73px;
    border-radius: 4px;
    background-color: #f5f6fa;
    text-align: left;
    padding: 20px 0 0 30px;
    margin: 30px 0 0 20px;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    margin: 15px;
    outline: none;
    border: 0;

    background: url("../../assets/close.png") no-repeat transparent;
    background-size: contain;
    background-position: center center;
  }

  a {
    display: flex;
    align-items: center;
    margin-left: 50px;
    margin-right: 20px;

    font-size: 16px;
    font-weight: bold;
    color: #111111;
    text-decoration: none;

    &:first-of-type {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
}

.complete-popup {
  width: 294px;
  height: 380px;
  border-radius: 4px;
  background-color: #ffffff;
  position: fixed;
  top: calc(50% - 190px);
  z-index: 10;
  align-items: center;
  right: calc(50% - 147px);
  left: calc(50% - 147px);

  .message-area {
    text-align: center;
    align-items: center;
    background-color: white;
    z-index: 99;
    bottom: 150px;
    position: absolute;
    left: calc(50% - 147px);
    width: 294px;
  }

  .message-mask {
    z-index: 90;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    filter: alpha(opacity=45);
    opacity: 0.45;
    -moz-opacity: 0.45;
    -khtml-opacity: 0.45;
  }

  .success-area {
    width: 100%;
  }

  .success-area i {
    position: relative;
    top: 10px;
    background: url("../../assets/success.png") no-repeat center;
    width: 28px;
    height: 28px;
    display: inline-block;
  }

  .success-area .message-content .message {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    color: #00a300;
  }

  .success-area .message-content .reason {
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    padding: 0 20px;
    margin-top: 16px;
  }

  .success-mark {
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #dddddd;
    left: 45%;
    position: relative;
    margin: 20px 0;
  }

  .btn-submit-outer {
    padding: 30px 0;
    text-align: center;
  }

  .btn-submit {
    outline: 0;
    border: 0;
    font-size: 18px;
    line-height: 48px;
    height: 48px;
    background-color: #2267ff;
    color: white;
    width: 270px;
    box-sizing: border-box;
    transition: all linear 150ms;
  }

  .btn-submit.btn-disabled,
  .btn-submit.btn-disabled:hover {
    background-color: #dddddd;
  }

  .btn-submit:hover {
    background-color: #0841be;
  }
}

.mask-div {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: black;
  opacity: 0.5;
}

.contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  &::after {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.call-icon {
  background-color: rgba(34, 103, 255, 0.1);
  &::after {
    background-image: url("../../assets/call.png");
  }
}

.kakao-icon {
  background-color: #fae100;
  &::after {
    background-image: url("../../assets/kakaotalk.png");
  }
}
