@use "sass:math";
@import "./variable.scss";

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@function pow($base, $exponent) {
    $res: 1;
    @if $exponent >= 0 {
        @for $i from 1 through $exponent {
            $res: $res * $base;
        }
    } @else {
        @for $i from $exponent to 0 {
            $ret: $ret / $x;
        }
    }

    @return $res;
}

@function toFixed($num, $digits: 6) {
    $pow: pow(10, $digits);
    @return math.div(round($num * $pow), $pow);
}

/**
 * Convert px to rem
 * for design with width 360px
 *
 */
@function rem($px) {
    @return toFixed(math.div($px, 36)) * 1rem;
}

// error tips
.message-error {
    min-width: 380px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #ebeef5;
    position: fixed;
    left: 50%;
    top: -100px;
    transform: translateX(-50%);
    transition: opacity .3s, transform .4s, top .4s;
    overflow: hidden;
    padding: 15px 15px 15px 20px;
    display: flex;
    align-items: center;
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
    text-align: center;
    opacity: .9;
    z-index: 10;

    &.enter {
        top: 30px;
    }

    &.leave {
        top: -100px;
    }
}

// 加载 Loading
.loading-box {
    min-height: 300px;
    max-height: 600px;
    overflow: hidden;
    position: relative;

    .loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -21px;
            margin-top: -21px;
            transition: 300ms;
            animation: 1.5s spin infinite linear;
        }
    }
}

// 分页
.page-box {
    .page-div {
        padding-top: 50px;
        text-align: center;

        a {
            cursor: pointer;
            color: black;
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 1px;
            text-align: center;
            line-height: 32px;
            border: 1px solid #e1e1e1;

            &.active {
                border: none;
                color: $active_color;
            }
        }
    }
}

.CouInput {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    border: 1px solid #dddddd;
    padding: 11px 16px;
    margin-bottom: 12px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;

    label {
        display: block;
        margin-top: 0;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        height: 15px;
        margin-bottom: 2px;
        color: #828285;
    }

    input {
        display: block;
        width: 100%;
        padding: 0;
        color: #111111;
        height: 19px;
        line-height: 19px;
        font-size: 16px;
        border: 0;
        outline: 0;
    }

    input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #aaaaaa;
    }

    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #aaaaaa;
    }

    input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #aaaaaa;
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #aaaaaa;
    }
}

// coupang select selector框
.CouSelect {
    //width:480px;

    border-radius: 10px;
    box-shadow: 3px 5px 10px 0 rgba(34, 49, 70, 0.1);
    border: 1px solid #dddddd;
    background-color: white;
    position: relative;
    margin: 0 0 12px 0;
    height: 60px;

    dt {
        position: relative;
        height: 50px;
        line-height: 50px;
        text-indent: 18px;
        color: $active_color;
        outline: none;

        .placeholder {
            color: #111111;
            display: block;
            line-height: 30px;
            font-size: 16px;
            font-weight: bold;
        }

        span {
            @extend .placeholder
        }

        label {
            display: block;
            margin-top: 0;
            font-size: 12px;
            line-height: 25px;
            height: 20px;
            color: #828285;
        }

        svg {
            pointer-events: none;
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: 5px;

            width: 8px;
            height: 8px;
            border: #444 solid;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
            margin-bottom: 10px;
        }
    }

    dd {
    /*    max-height: 480px;
        background: white;
        overflow: auto;
        position: absolute;
        left: -40px;
        right: -1px;
        transform: translateY(-3px);
        border: 1px solid #dddddd;
        // border-top:none;
        margin-top: 16px;
        border-radius: 6px;
        z-index: 2;
        bottom: 0;
        top: 0;*/

        margin: 0;

        & > div {
            position: absolute;
            width: 100%;

            background-color: white;
            border: 1px solid #ddd;
            z-index: 2;
            box-shadow: 3px 5px 10px 0;

            ol {
                list-style: none;
                padding: 0;
                margin: 5px 0;
            }
        }
    }

    dd li {
        cursor: pointer;
        color: #111111;
        height: 40px;
        line-height: 40px;
        text-indent: 20px;
        border-bottom: 1px solid #f6f6f6;

        &:hover, &.active {
            background: #f6f6f6;
            //color: $active_color;
        }
    }
}

.CouSelect.emptyLabel {
    height: 42px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #c4cdd5;

    dt {
        height: 40px;
        text-indent: 14px;

        svg {
            top: 25%;
        }
    }

    .placeholder {
        line-height: 42px;
        font-weight: normal;
    }
}

.CouTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #aaa;

    th {
        vertical-align: baseline;
        font-size: 16px;
        padding: 15px;
        background-color: #3784f1;;
        // border:1px solid #3F3F3F;
        text-align: left;
        color: #fff;
    }

    td {
        vertical-align: text-top;
        padding: 30px;
        font-size: 16px;
    }
}

// RadioBox
input[type=radio] {
    display: none;
}

input[type=radio] + label {
    display: inline-flex;
}

input[type=radio] + label i {
    width: 6px;
    height: 6px;
    background: #CCC;
}

input[type=radio] + label svg {
    margin-right: 4px;
    transform: scale(0.9);
}

input[type=radio] + label .outter {
    stroke: #CCC;
    stroke-width: 2;
    fill: white;
}

input[type=radio] + label .inner {
    stroke: none;
    fill: #CCC;
}

input[type=radio]:checked + label .outter {
    stroke: #2267ff;
    stroke-width: 2;
    fill: white;
}

input[type=radio]:checked + label .inner {
    stroke: none;
    fill: #2267ff;
}
