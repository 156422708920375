@import "./common.scss";

.index-page {
  //padding-top: 10px;
  //overflow-x: hidden;
  //height: 500px;
  //width: 100%;
  flex: 1 1;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
  padding: 0 12px;

  h2 {
    text-align: left;
    //padding-left: rem(20);
    font-size: 24px;
    letter-spacing: -0.3px;
    color: #111111;
  }

  .urgent-div {
    position: relative;
    height: 50px;

    .loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);

      img {
        position: absolute;
        top: 50%;
        left: 30%;
        margin-left: -21px;
        margin-top: -21px;
        transition: 300ms;
        animation: 1.5s spin infinite linear;
      }
    }
  }

  .welfare {
    display: flex;
    position: relative;
    margin-bottom: 16px;
    div {
      text-align: left;
      font-size: 14px;
      letter-spacing: -0.3px;
      color: #333333;
      line-height: 22px;
    }

    img {
      position: absolute;
      top: 50px;
      right: -6px;
      object-fit: contain;
      width: 190px;
    }

    .recruit-inquiry {
      display: flex;
      align-items: center;
      outline: none;
      border: 0;
      padding: 0;
      margin-top: 8px;
      background-color: #ffffff;

      font-size: 14px;
      line-height: 1;
      font-weight: bold;
      color: #2267ff;

      .contact-icon {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        margin-right: 0;
        &::after {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .divide-area {
    height: 10px;
    background-color: #f4f5f8;
    width: calc(100% + 24px);
    margin-left: -12px;
  }

  .expand {
    position: relative;
    top: 260px;
  }

  .person-div {
    height: 350px;
  }

  .person-info {
    text-align: left;

    .tc-area {
      padding: rem(20) rem(20);
      border-radius: 4px;
      background-color: #f5f6f7;
      font-size: 14px;
      line-height: 1.29;
      color: #555555;

      p {
        font-size: 14px;
        line-height: 1.29;
        color: #2267ff;
        margin: 5px 0 5px 0;
      }

      .icon-right {
        background: url("../../assets/arrow-r.png") no-repeat transparent;
        background-size: contain;
        background-position: center center;
        width: 15px;
        height: 10px;
        display: inline-block;
        //right: 0;
        //top: 50%;
        margin: 3.5px 0 0 5px;
        transition: all linear 200ms;
      }
    }
  }

  .submit-person {
    //padding: 0 rem(20);
    height: 62px;
    //bottom: 3px;
    //position: fixed;
    //width: calc(100% - 1.1rem);
    margin-top: 35px;

    button {
      width: 100%;
      height: 52px;
      border: 0;
      border-radius: 4px;
      background-color: #2267ff;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;

      &.btn-disabled {
        background-color: #dddddd;
      }
    }
  }

  .hide {
    display: none;
  }

  .prefer-div {
    height: 350px;
    position: relative;

    .prefer-description {
      font-size: 14px;
      line-height: 1.43;
      color: #808080;
    }
  }

  .work-info {
    text-align: left;

    h5 {
      padding: 19px 65px 18px 16px;
      border: 1px solid #dddddd;
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: #111111;
      border-radius: 4px;
      position: relative;
      margin: 10px 0;

      &.hasLicense {
        border-color: #2267ff;
      }

      .work-checkbox {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);

        li {
          display: inline-block;
          padding: 0 0 0 30px;
          font-size: 16px;
          line-height: 30px;
          height: 30px;
          cursor: pointer;
          margin-bottom: 0;

          .fa {
            display: inline-block;
            margin-right: 6px;
            font-size: 0;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            vertical-align: middle;
          }

          .fa-square-o {
            background-image: url("../../assets/check-disable.png");

            &.fa-check-square-o {
              background-image: url("../../assets/check.png");
            }
          }
        }
      }
    }
  }

  .map-box {
    padding-top: 10px;

    ol,
    li,
    h4 {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    h4 {
      padding: 20px 0 12px 0px;
      border-bottom: 1px solid #eee;
    }

    img {
      display: block;
      float: right;
    }

    label {
      line-height: 24px;
      width: 80%;

      span {
        width: 90%;
      }
    }

    ol {
      padding: 16px;

      li {
        padding: 12px 0 12px 0;
      }
    }

    .map-tip {
      font-size: 14px;
      line-height: 1.43;
      color: #aaaaaa;
      margin: 20px 0;
    }
  }

  .fixed-map-div {
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);

    h2 {
      margin: 0;
      text-align: right;
      padding-right: 10px;
      font-size: 32px;
      height: 38px;
    }

    > div {
      width: 96%;
      height: 500px;
      position: absolute;
      top: 50%;
      left: 50%;
      background: white;
      transform: translate(-50%, -50%);
    }

    .map-ref {
      height: calc(490px - 38px);

      iframe {
        height: calc(490px - 38px);
        width: 98%;
      }
    }
  }

  .prefer-period {
    //height: 170px;
    padding-top: 10px;
    border-top: 1px solid #dddddd;
  }
}

.tc-page {
  padding: 0 12px;
  text-align: left;
  font-size: 16px;
  color: #111111;
  line-height: 23px;
  letter-spacing: -0.5px;

  h5 {
    font-size: 14px;
  }

  .icon-left {
    background: url("../../assets/arrow-r-active.png") no-repeat transparent;
    background-size: contain;
    background-position: center center;
    width: 12px;
    height: 16px;
    display: inline-block;
    //right: 0;
    //top: 50%;
    margin: 3.5px 5px 0 0;
    transition: all linear 200ms;
    transform: rotate(180deg);
    transform-origin: center center;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    color: #111111;
  }

  span {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: #2267ff;

    i {
      display: inline-block;
      width: 15px;
      height: 10px;
      background: url("../../assets/arrow-right.png") no-repeat center;
    }
  }

  strong {
    font-size: 22px;
  }
}

.invalid-input {
  border: 1px solid #d82222;
}
