$active_color: #2a7ff6;
$point: #2a7ff6;
$basicFont: #111111;
$lineOrBg: #e1e1e1;
$lineOrBgLighter: #f6f6f6;
$tabItemColor: #4a4a4a;

@mixin hairLine($direction, $color, $pos: 0) {
  content: "";
  display: block;
  position: absolute;
  @if $direction == "top" or $direction == "bottom" {
    @if $direction == "top" {
      top: $pos;
    } @else if $direction == "bottom" {
      bottom: $pos;
    }
    height: 1px;
    background-color: $color;
  } @else if $direction == "left" or $direction == "right" {
    @if $direction == "left" {
      left: $pos;
    } @else if $direction == "right" {
      right: $pos;
    }
    width: 1px;
    background-color: $color;
  }
}
